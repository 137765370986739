export const init = () => {
    const checkRegion = region => !!document.getElementsByClassName(region).length

    const options = {
        mode: 'scroll',
        resizeMode: '',
        actions: [
            {
                visibility: [0, 1.0],
                type: 'seek',
                frames: [0, 80],
            },
        ]
    };

    if (checkRegion('malta-page')) {
        ['#path01','#path02','#path03','#path05','#path06',
            '#path10M','#path11M','#path12', '#path15','#path14','#path11'].map((path) => {
            LottieInteractivity.create({
                player: path,
                ...options
            });
        })
    }

    if (checkRegion('madrid-page')) {
        ['#path01','#path02','#path03','#path04','#path05','#path06',
            '#path08M','#path09','#path09M','#path10M','#path12', '#path13', '#path15', '#path11'].map((path) => {
            LottieInteractivity.create({
                player: path,
                ...options
            });
        })
    }

    if (checkRegion('home-page')) {
        ['#path01','#path02','#path03','#path04','#path05','#path06','#path07',
            '#path07M','#path12', '#path08', '#path08M', '#path09', '#path09M', '#path11', '#path10'].map((path) => {
            LottieInteractivity.create({
                player: path,
                ...options
            });
        })
    }

        if (checkRegion('global-page')) {
        ['#path01','#path02','#path03','#path04','#path05','#path06','#path07',
            '#path07M','#path08','#path08M','#path09', '#path09M', '#path12', '#path11', '#path10',].map((path) => {
            LottieInteractivity.create({
                player: path,
                ...options
            });
        })
    }


    if (checkRegion('israel-page')) {
        ['#path01','#path02','#path03','#path04','#path05','#path06','#path07',
            '#path07M','#path08','#path08M', '#path12', '#path11', '#path10',].map((path) => {
            LottieInteractivity.create({
                player: path,
                ...options
            });
        })
    }
}
