export const init = () => {
    const screenWidth = $(window).width()
    const linksFromNotToggle = ['join-us']
    const noNeedToApplyToggle = linksFromNotToggle.some(item => window.location.href.indexOf(item) > -1);

    if (noNeedToApplyToggle) {
        window.addEventListener('load',function () {
            const header = $('#header')
            const logo = $('#logo')
            const hamburger = $('#hamburger')

            header.addClass('light')
            logo.addClass('dark')
            hamburger.addClass('dark')
        });
    }

    $(window).scroll(function () {
        const scroll = $(window).scrollTop();
        const header = $('#header')
        const logo = $('#logo')
        const hamburger = $('#hamburger')

        if (scroll >= 2 || noNeedToApplyToggle) {
            header.addClass('light')
            logo.addClass('dark')
            hamburger.addClass('dark')

        } else {
            header.removeClass('light')
            logo.removeClass('dark')
            hamburger.removeClass('dark')
        }
    });

    if (screenWidth >= 1100) {
        $(".parallax, [data-paroller-factor]").paroller({
            factor: 0.1,            // multiplier for scrolling speed and offset
            type: 'foreground',     // background, foreground
            direction: 'vertical', // vertical, horizontal
        });
    }

}
