export const init = () => {
    const list = $('.js-lang-menu li')

    list.on('click', function () {
        const current = $(this)

        list.each(function () {
            if ($(this).not(current).length) {
                $(this).removeClass('active-lang')
            } else {
                $(this).addClass('active-lang')
            }
        })
    });
}
