export const init = () => {
    $('.point').on('inview', function (event, isInView) {
        const random = max => Math.floor(Math.random() * Math.floor(max));
        const delay = () => random(4)

        if (isInView && !$(this).hasClass('animated')) {
            $(this).css({'animation': `fadeInOut 4s ${delay()}s infinite`})
            $(this).addClass('animated')
        }
    });
}