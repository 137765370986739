const globalThis = window;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("slick-carousel")
require('paroller.js')
global.$ = require('jquery')
global.jQuery = $


const images = require.context('../images', true);
const imagePath = (name) => images(name, true);


// Styles
import './styles/application'
// Vendors
import './js/front-office/vendors/onelink-smart-script-v2.0.1'
//Utils
import { generateOneLinkUrl } from "./js/front-office/utils/generate_one_link_url"

//Components
import { contactAnchor, aboutAnchor } from "./js/front-office/components/anchor"
import { drawPoints } from "./js/front-office/components/points-effect"
import * as headerLinks from "./js/front-office/components/header_links"
import * as slickSlider from './js/front-office/components/slick_slider'
import * as hamburger from './js/front-office/components/hamburger'
import * as points from './js/front-office/components/points'
import * as toggleHeaderLightAndDark from './js/front-office/components/toggle_header_light_and_dark'
import * as arrows from './js/front-office/components/arrows'
import * as stockdio from './js/front-office/components/stockdio_script'
import * as mobileAccordion from './js/front-office/components/mobile_accordion'
import * as desktopAccordion from './js/front-office/components/desktop_accordion'
import * as check_lang from './js/front-office/components/check_lang'
import * as toggleTestimonial from './js/front-office/components/toggle_testimonial'

document.addEventListener("turbolinks:load", function() {
    generateOneLinkUrl();
    contactAnchor();
    aboutAnchor();
    drawPoints();
    headerLinks.init();
    slickSlider.init();
    hamburger.init();
    points.init();
    toggleHeaderLightAndDark.init();
    arrows.init();
    stockdio.init();
    mobileAccordion.init();
    desktopAccordion.init();
    check_lang.init();
    toggleTestimonial.init();

})


