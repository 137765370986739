const choseRegionOneLink = () => {
    if (!!window.location.pathname.match(/israel/) || !!window.location.pathname.match(/he/)) {
        return "https://goto-israel.onelink.me/z0xH"
    } else if (!!window.location.pathname.match(/es/)) {
        return "https://gotoglobal.onelink.me/CfTm"
    } else if (!!window.location.pathname.match(/spain/)) {
        return "https://gotoglobal.onelink.me/CfTm/lmze580i"
    } else {
        return "https://gotoglobal.onelink.me/6jBs"
    }
}

const oneLinkURL = choseRegionOneLink();


const mediaSource = {keys: ["UTM_source", "utm_source"], defaultValue: "any_source"};
const channel = {keys: ["UTM_medium", "utm_medium"]};
const campaign = {keys: ["UTM_campaign", "utm_campaign"]};
const custom_ss_ui = {paramKey: "af_ss_ui", defaultValue: "true"};

export const generateOneLinkUrl = () => {
//Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
//Onelink URL is generated
    const smartScript = window.AF_SMART_SCRIPT;
    const smartScriptHomeSpain = window.AF_SMART_SCRIPT;
    const smartScriptHomeIsrael = window.AF_SMART_SCRIPT;

    if (!!smartScript) {
        const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL,
            afParameters: {
                mediaSource,
                channel,
                campaign,
                afCustom: [
                    custom_ss_ui
                ]
            }
        })

        const result_url = result.clickURL;
        if (result_url) {
            // document.getElementById('smart_script_link').setAttribute('href', result_url);
            const el = document.querySelectorAll("#smart_script_link");
            let i = 0, ellen = el.length - 1;
            for (; i <= ellen; i++) {
                el[i].setAttribute('href', result_url)
            }

            var tilda_el = document.querySelectorAll("a[href='https://gotoglobal.onelink.me/CfTm/lmze580i']");
            var tilda_i = 0, tilda_ellen = tilda_el.length - 1;
            for (; tilda_i <= tilda_ellen; tilda_i++) {
                tilda_el[tilda_i].setAttribute('href', result_url)
            }
        }
    }

    if (!!smartScriptHomeSpain) {
        const oneLinkURL = "https://gotoglobal.onelink.me/CfTm";
        const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL,
            afParameters: {
                mediaSource,
                channel,
                campaign,
                afCustom: [
                    custom_ss_ui
                ]
            }
        })

        const result_url = result.clickURL;
        if (result_url) {
            var spain_el = document.querySelectorAll("a[href='https://gotoglobal.onelink.me/CfTm']");
            var spain_i = 0, spain_ellen = spain_el.length - 1;
            for (; spain_i <= spain_ellen; spain_i++) {
                spain_el[spain_i].setAttribute('href', result_url)
            }
        }
    }

    if (!!smartScriptHomeIsrael) {
        const oneLinkURL = "https://goto-israel.onelink.me/z0xH";
        const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL,
            afParameters: {
                mediaSource,
                channel,
                campaign,
                afCustom: [
                    custom_ss_ui
                ]
            }
        })

        const result_url = result.clickURL;
        if (result_url) {
            var heb_el = document.querySelectorAll("a[href='https://goto-israel.onelink.me/z0xH']");
            var heb_i = 0, heb_ellen = heb_el.length - 1;
            for (; heb_i <= heb_ellen; heb_i++) {
                heb_el[heb_i].setAttribute('href', result_url)
            }
        }
    }
}

