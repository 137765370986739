const random = max => Math.floor(Math.random() * Math.floor(max));

export const drawPoints = () => {
  const containers = document.querySelectorAll('.points-effect')
  containers.forEach(item => {
    const height = item.offsetHeight;
    const colors = ['purple', 'pink', 'yellow', 'blue'];
    const density = height / 18

    const posX = () => random(100)
    const posY = () => random(100)

    const colorClass = () => colors[random(4)]
    const mobileClass = i => i % 3 ? 'xs-hidden' : ''

    for (let i = 0; i < density; i++) {
      const point = `<div
                        class="point ${ colorClass() } ${ mobileClass(i) }" style="left: ${ posX() }%; top: ${ posY() }%;">
                     </div>`

      item.innerHTML += point
    }
  })
}

