export const init = () => {

    $(function () {
        const item = $('.js-accordion-item')
        const subItem = $('.js-accordion-subitem')
        const list = $('.js-accordion-list')
        const subList = $('.js-accordion-sublist')

        item.on('click', function () {
            const currentItem = $(this)

            item.each(function () {
                if ($(this).not(currentItem).length) {
                    $(this).removeClass('open').find('.js-accordion-list').slideUp(500);
                }
            })

            currentItem.hasClass('open')
                ? $(currentItem).removeClass('open').find('.js-accordion-list').slideUp(500)
                : $(currentItem).addClass('open').find('.js-accordion-list').slideDown(700);

        })

        subItem.on('click', function (e) {
            e.stopPropagation();

            const currentSubItem = $(this)

            subItem.each(function () {
                if ($(this).not(currentSubItem).length) {
                    $(this).removeClass('open').find('.js-accordion-sublist').slideUp(500)
                }
            })

            currentSubItem.hasClass('open')
                ? $(currentSubItem).removeClass('open').find('.js-accordion-sublist').slideUp(500)
                : $(currentSubItem).addClass('open').find('.js-accordion-sublist').slideDown(700)
        })

    });
}
