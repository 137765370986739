// TODO: move to application
export const contactAnchor = () => {
    $('.js-contact-anchor').on('click', function () {
        const navHeight = $('.header').innerHeight();
        $("html, body").animate({scrollTop: $('.flexibility').offset().top - navHeight - 25}, 500);
    });
}

export const aboutAnchor = () => {
    $('.js-about-anchor').on('click', function () {
        const navHeight = $('.header').innerHeight();
        $("html, body").animate({scrollTop: $('.container-business').offset().top - navHeight - 25}, 500);
    });
}