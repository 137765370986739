export const init = () => {

    if (typeof (stockdio_events) == "undefined") {
        const stockdio_events = true;
        const stockdio_eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        const stockdio_eventer = window[stockdio_eventMethod];
        const stockdio_messageEvent = stockdio_eventMethod == "attachEvent" ? "onmessage" : "message";
        stockdio_eventer(stockdio_messageEvent, function (e) {
            if (typeof (e.data) != "undefined" && typeof (e.data.method) != "undefined") {
                eval(e.data.method);
            }
        }, false);
    }

}

