import {drawPoints} from "./points-effect";
import {termsTablesScroll} from "../utils/termsTablesScroll"

export const init = () => {
    const screenWidth = $(window).width()

    // Sliders
    const slider = $('.hero-slider')
    slider.slick({
        infinite: true,
        // variableWidth: true,
        // centerMode: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    });

    const isRtlOn = !!$('.israel-hero-rtl').length
    const sliderMadrid = $('.hero-slider-madrid')
    const sliderMalta = $('.hero-slider-malta')
    const sliderRtlIsrael = $('.hero-slider-israel-rtl')
    const sliderIsrael = $('.hero-slider-israel')

    sliderMadrid.slick({
        infinite: true,
        variableWidth: true,
        // centerMode: true,
        dots: false,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    })

    sliderMalta.slick({
        infinite: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1.99,
                    slidesToScroll: 1,
                }
            },
        ]
    });

    sliderIsrael.slick({
        infinite: true,
        variableWidth: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        rtl: isRtlOn,
        arrows: false,
        draggable: false,
    })

    sliderRtlIsrael.slick({
        infinite: true,
        variableWidth: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        rtl: isRtlOn,
        arrows: false,
        draggable: false,
    })

    setInterval(() => {
        sliderMadrid.slick("slickPrev")
        sliderIsrael.slick("slickPrev")
        sliderRtlIsrael.slick("slickNext")
    }, 3000) // slick native rtl method breaks layout

    setInterval(() => {
        slider.slick("slickPrev")
    }, 3000) // slick native rtl method breaks layout

    $('.roll-slider').slick({
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false
                }
            }]
    });

    $('.business-slider').slick({
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
    });

    $('.business-slider-rtl').slick({
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        rtl: true
    });

    $('.b2b-introduce-slider, .b2b-introduce-slider-rtl').slick({
        infinite: true,
        dots: true,
        slidesToShow: 4.99,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1.7,
                }
            },
        ]
    });


    $('.b2b-multimodal-fleet-slider').slick({
        infinite: true,
        dots: true,
        slidesToShow: 1.66,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        rtl: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1.66,
                }
            },
        ]
    });


    $('.b2b-testimonials-slider, .b2b-testimonials-slider-rtl').slick({
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });


    const carsSlider = screenWidth >= 1100 ? $('.cars-slider-desktop') : $('.cars-slider-mobile')
    const carsSliderLength = screenWidth >= 1100 ? $('.cars-slider-desktop .slider-item').length : $('.cars-slider-mobile .slider-item').length
    carsSlider.slick({
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 2000,
    });

    carsSlider.on('inview', function (event, isInView) {
        if (!isInView) {
            carsSlider.slick("slickGoTo", 0);
        }
    });

    const carsIsraelSlider = screenWidth >= 1100 ? $('.cars-slider-israel-desktop') : $('.cars-slider-israel-mobile')
    carsIsraelSlider.slick({
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        autoplay: true,
    });

    setInterval(() => {
        carsIsraelSlider.slick("slickPrev")
    }, 3000)

    carsIsraelSlider.on('inview', function (event, isInView) {
        if (!isInView) {
            carsIsraelSlider.slick("slickGoTo", 0);
        }
    });

    const carsIsraelRtlSlider = screenWidth >= 1100 ? $('.cars-slider-rtl-israel-desktop') : $('.cars-slider-rtl-israel-mobile')
    carsIsraelRtlSlider.slick({
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        rtl: true
    });


    $(".dropdown").click(function () {
        $('.dropdown-content').toggle();
    });
    $(document).on('click', function (e) {
        if (!$(e.target).closest(".dropdown").length) {
            $('.dropdown-content').hide();
        }
        e.stopPropagation();
    });

    $('.slider-item').click(function () {
        var href = $(this).find('a').attr('href')
        if(href)
        window.open(href, '_blank');
    })

    window.jq = $;

    $('p[data-f-id=pbf]').remove()


    termsTablesScroll()

}




