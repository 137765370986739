// desktop accordion on hover
export const init = () => {
    $(function () {
        const item = $('.js-accordion-item')
        const subItem = $('.js-accordion-subitem')
        const list = $('.js-accordion-list')
        const subList = $('.js-accordion-sublist')
        const listItems = list.find('nav')
        item.on('mouseover', function () {
            item.removeClass('active')
            $(this).addClass('active')
            const region = $(this).data('region')

            listItems.each(function () {
                const listRegion = $(this).data('region')

                if (region === listRegion) {
                    $(this).addClass('active')
                } else {
                    $(this).removeClass('active')
                }
            })
        })
        listItems.on('mouseleave', function () {
            item.removeClass('active')
            listItems.removeClass('active')
        })
    });
    $('.js-nav-close-area').click(function(e) {
        e.stopPropagation();
        $(this).toggleClass('open');
        $('.hamburger').toggleClass('is-active');
        $('.js-desktop-nav').toggleClass('open');
    });
}






