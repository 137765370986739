export const init = () => {
    let currentUrl = window.location.pathname;

    $(".drop-item-link").each(function () {
        if ($(this).attr("href") === currentUrl) {
            let child = $(this).parent('.drop-item');
            $(child).addClass("active");
            $(".dropbtn").text($(this).text())
        }
    });

    $(".js-change-lang").each(function () {
        if (currentUrl.includes($(this).text())) {
            $(this).addClass("active");
        }
    });
}
