export const init = () => {
    const activeRegion = $('.active-region')

    $('.hamburger').on('click', function () {
        $('.hamburger').toggleClass('is-active');
        $('.js-mobile-nav').toggleClass('open');

        $('.js-desktop-nav').toggleClass('open');
        $('.js-nav-close-area').toggleClass('open')
        $('body').toggleClass('no-scroll');

        if( ($(this).hasClass('is-active'))) {
            activeRegion.addClass('active')
        }
    });

    $('.hamburger-close').click(function () {
        $('body').removeClass('overflow-hidden')
    });
    $('.hamburger-open').click(function () {
        $('body').addClass('overflow-hidden')
    });

}