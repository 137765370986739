export function init() {

    let timeout


    let noOfCharac = 146;
    let contents = document.querySelectorAll('.content');

    let button = $(".js-testimonial-btn");
    button.on('click', readMore);

    $(window).on('resize', function () {

        timeout && clearTimeout(timeout)

        timeout = setTimeout(() => {
            console.log(button);
            console.log('resize')
            $(".js-testimonial-btn").off('click', readMore).on('click', readMore);
        }, 500)
    })

    contents.forEach(content => {
        if (content.textContent.length < noOfCharac) {
            content.nextElementSibling.style.display = "none";
        } else {
            let displayText = content.textContent.slice(0, noOfCharac);
            let moreText = content.textContent.slice(noOfCharac);
            content.innerHTML = `${displayText}<span class="dots">...</span><span class="hide more">${moreText}</span>`
        }
    })

    function readMore() {
        const testimonialsItem = $(this).closest('.js-testimonials-item')
        testimonialsItem.toggleClass('show-more')

        const isShowMore = testimonialsItem.hasClass('show-more')

        const dots = testimonialsItem.find('.dots')
        const more = testimonialsItem.find('.more')

        if (isShowMore) {
            dots.addClass("hide");
            more.removeClass("hide");
            $(this).addClass("toggle-arrow")
            $(this).html("SHOW LESS")
        } else {
            dots.removeClass("hide");
            more.addClass("hide");
            $(this).removeClass("toggle-arrow")
            $(this).html("SHOW MORE")
        }

    }
}






// export function init() {
//
//     // if(window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth){
//     //     alert("Clickable btn! Please use Portrait mode!");
//     // }
//
//     // if (window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth) {
//     //     alert("Clickable btn!");
//     let timeout
//
//
//     let noOfCharac = 146;
//     let contents = document.querySelectorAll('.content');
//
//     let button = $(".js-testimonial-btn");
//     button.on('click', readMore);
//
//     $(window).on('resize', function () {
//         if (window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth) {
//             timeout && clearTimeout(timeout)
//             timeout = setTimeout(() => {
//                 button.off('click', readMore).on('click', readMore);
//             }, 200)
//         }
//     })
//
//     contents.forEach(content => {
//         if (content.textContent.length < noOfCharac) {
//             content.nextElementSibling.style.display = "none";
//         } else {
//             let displayText = content.textContent.slice(0, noOfCharac);
//             let moreText = content.textContent.slice(noOfCharac);
//             content.innerHTML = `${displayText}<span class="dots">...</span><span class="hide more">${moreText}</span>`
//         }
//     })
//
//     function readMore(btn) {
//         const testimonialsItem = button.closest('.js-testimonials-item')
//         testimonialsItem.find('.dots').toggleClass("hide");
//         testimonialsItem.find('.more').toggleClass("hide");
//
//         $(".js-testimonial-btn").toggleClass("toggle-arrow")
//
//         // console.log(testimonialsItem)
//
//         for (let i = 0; i < button.length; i++) {
//             button[i].innerHTML == "SHOW MORE" ?
//                 button[i].innerHTML = "SHOW LESS"
//                 :
//                 button[i].innerHTML = "SHOW MORE"
//         }
//
//     }
// }